import React from 'react';
import { notifications } from '../constants';
import { pendingDelivery, inProgress, completed } from '../Components/RemittanceChipWithTooltip';
import { Typography } from '@mui/material';
import { marketplaceLogos } from '../constants/marketplace';

export const sleep = (m) => new Promise((r) => setTimeout(r, m));

//so as not to write every time if else after requests
export function handleAction({ action, callback, errorHandler }) {
  if (action.error) {
    errorHandler && errorHandler(action);
  } else {
    callback && callback(action);
  }
}

// add ENTER and ESC handlers to the input
export const createFieldKeyDownHandler = (onEnter, onEsc) => (event) => {
  event.stopPropagation();
  if (onEnter && event.which === 13) {
    // enter
    onEnter(event);
  } else if (onEsc && event.which === 27) {
    //esc
    onEsc(event);
  }
};

export const deepClone = (data) => JSON.parse(JSON.stringify(data));

export const getFileNameFromPath = (path) => {
  const split = path.split('/');
  return split[split.length - 1];
};

export const getColorSet = (chartName) => {
  switch (chartName) {
    case 'Shipping Status':
      return getShipmentMetricsBarColors;
    default:
      return getStatusColor;
  }
};

export const getShipmentMetricsBarColors = (label, palette) => {
  switch (label) {
    case 'UNASSIGNED':
      return palette.barChart.color2;
    case 'PENDING PICKUP':
      return palette.barChart.color10;
    case 'TRANSIT HOLD':
      return palette.barChart.color12;
    case 'IN TRANSIT':
      return palette.barChart.color1;
    case 'OUT FOR DELIVERY':
      return palette.barChart.color13;
    case 'DELIVERED':
      return palette.barChart.color4;
    case 'RETURN IN PROGRESS':
      return palette.barChart.color9;
    case 'RETURNED':
      return palette.barChart.color11;
    case 'CANCELED':
      return palette.barChart.color14;
    case 'LOST/DAMAGED':
      return palette.barChart.color7;
    default:
      return palette.barChart.color7;
  }
};
export const getSizeChipColor = (text, palette) => {
  let color;
  let label = text?.includes(':') ? text.split(':')[0].trim() : text;
  switch (label) {
    case 'X-SMALL':
      color = palette.text.disabled;
      break;
    case 'X-SMALL+':
      color = palette.text.secondary;
      break;
    case 'SMALL':
      color = palette.success.main;
      break;
    case 'SMALL+':
      color = palette.success.dark;
      break;
    case 'MEDIUM':
    case 'STANDARD':
      color = palette.warning.main;
      break;
    case 'MEDIUM+':
    case 'STANDARD+':
      color = palette.warning.dark;
      break;
    case 'LARGE':
      color = palette.info.main;
      break;
    case 'LARGE+':
      color = palette.info.dark;
      break;
    case 'X-LARGE':
      color = palette.error.main;
      break;
    case 'X-LARGE+':
      color = palette.error.dark;
      break;
    case 'BULKY':
      color = palette.primary.light;
      break;
    case 'BULKY+':
      color = palette.primary.main;
      break;
    default:
      color = palette?.grey[500];
      break;
  }

  return color;
};

export const getPackagingSizeChipColor = (text, palette) => {
  let color;
  let label = text?.includes(':') ? text?.split(':')[0]?.trim() : text;
  switch (true) {
    case label.includes('- XS'):
      color = palette.text.disabled;
      break;
    case label.includes('- XS+'):
      color = palette.text.secondary;
      break;
    case label.includes('- S'):
      color = palette.success.main;
      break;
    case label.includes('- S+'):
      color = palette.success.dark;
      break;
    case label.includes('- M'):
      color = palette.warning.main;
      break;
    case label.includes('- M+'):
      color = palette.warning.dark;
      break;
    case label.includes('- L'):
      color = palette.info.main;
      break;
    case label.includes('- L+'):
      color = palette.info.dark;
      break;
    case label.includes('- XL'):
      color = palette.error.main;
      break;
    case label.includes('- XL+'):
      color = palette.error.dark;
      break;
    // case 'BULKY':
    //   color = palette.primary.light;
    //   break;
    // case 'BULKY+':
    //   color = palette.primary.main;
    //   break;
    default:
      color = palette.grey[500];
      break;
  }

  return color;
};
export const getCarrierColor = (label, palette) => {
  let color;

  switch (label) {
    case 'Shopee':
      color = '#82FFF6';
      break;
    case 'Locad':
      color = '#94FFA1';
      break;
    case 'Lazada':
      color = '#FF875C';
      break;
    case 'TikTok':
      color = '#C2BDFF';
      break;
    default:
      color = palette.grey[500];
      break;
  }

  return color;
};

export const getPaymentStatusColor = (label) => {
  let color;

  switch (label) {
    case 'PAID':
      color = 'var(--colors-secondary)';
      break;
    case 'PARTIALLY PAID':
      color = 'var(--color-alert-orange)';
      break;
    case 'UNPAID':
      color = 'var(--color-alert-red)';
      break;
    default:
      color = 'grey[500]';
      break;
  }
  return color;
};
export const getStatusColor = (label, palette) => {
  let color;

  switch (label) {
    case 'new':
    case 'New':
    case 'NEW':
    case 'QUEUED':
    case 'RELEASED':
    case 'ASN_PENDING':
    case 'PENDING_APPROVAL':
    case 'FastMoving':
    case 'X-Small':
    case 'Yes':
    case 'OPEN':
    case 'Open':
    case 'open':
    case 'CONFIRMED':
    case 'PENDING':
    case 'mapped':
      color = '#42D48B';
      break;

    case 'Ready For Pickup':
    case 'PICKING':
    case 'No':
    case 'PROCESSING':
    case 'Not Present':
    case 'FastMoving-LowStock':
    case 'SlowMoving':
    case 'UNASSIGNED':
      color = palette.warning.light;
      break;

    case 'RETURN_COMPLETED':
    case 'ON_HOLD':
    case 'PREORDER':
    case 'SHEDULED':
    case 'PARTIAL':
    case 'PARTIAL_PICKED':
    case 'VALIDATING':
    case 'In Progress':
    case 'RETURN_IN_PROGRESS':
    case 'SlowMoving-WithStock':
    case 'LowStock':
    case 'Inactive':
    case 'Not Processed':
    case 'ARRIVED_AT_RETURN_HUB':
    case 'RETURN_TRIGGERED':
    case 'RETURN_PARCEL_SORT_READY':
    case 'OUT_FOR_RETURN':
    case 'BATCH':
      color = palette.warning.main;
      break;

    case 'RETURNED':
    case inProgress:
      color = '#FF6B00';
      break;

    case 'Packed':
    case 'PACKED':
    case 'PACKING':
    case 'STOCK_RECEIVED':
    case 'READY_FOR_PICKUP':
    case 'READY_FOR_PICKING':
    case 'Standard':
    case 'FULFILLed':
    case 'Fulfilled':
    case 'fulFilled':
      color = palette.info.light;
      break;

    case 'Ready to Ship':
    case 'READY_FOR_DISPATCH':
    case 'QUALITY_CHECK':
    case 'STOCK_COUNT':
    case 'Large':
    case 'DISPATCHED':
      color = palette.info.main;
      break;

    case 'TRANSIT_HOLD':
      color = '#2C0065';
      break;

    case 'Shipped':
    case 'In Transit':
    case 'IN TRANSIT':
    case 'IN_TRANSIT':
    case 'PACKAGE_PICKED':
    case 'ENROUTE_TO_SORT':
    case 'ARRIVED_AT_SORT':
    case 'ENROUTE_TO_DIST':
    case 'ARRIVED_AT_DIST':
    case 'PARCEL_SORT_READY':
    case 'RESCHEDULE_DELIVERY':
      color = palette.info.dark;
      break;

    case 'OUT_FOR_DELIVERY_1':
    case 'OUT_FOR_DELIVERY_2':
    case 'OUT_FOR_DELIVERY_3':
    case 'OUT_FOR_DELIVERY':
    case pendingDelivery:
      color = '#9A72CD';
      break;

    case 'PICKED_UP':
    case 'PICKED':
    case 'ASSIGNED':
    case 'PICKUP_PENDING':
    case 'PENDING_PICKUP':
    case 'ASN_APPROVED':
    case 'APPROVED':
      color = palette.primary.light;
      break;

    case 'Cancelled':
    case 'CANCELLED':
    case 'ASN_REJECTED':
    case 'FastMoving-OutOfStock':
    case 'OutOfStock':
    case 'FAILED':
    case 'Failed':
    case 'Bulky':
    case 'PICKUP_FAILED_1':
    case 'PICKUP_FAILED_2':
    case 'PICKUP_FAILED_3':
    case 'EXPIRY':
    case 'FAILED_DELIVERY':
    case 'LATE':
    case 'unmapped':
      color = palette.error.main;
      break;

    case 'CANCELLATION_IN_PROGRESS':
    case 'ERROR':
    case 'PUTAWAY':
    case 'UNPAID':
    case 'X-Large':
    case 'DELIVERY_FAILURE_1':
    case 'DELIVERY_FAILURE_2':
    case 'DELIVERY_FAILURE_3':
    case 'LOST':
    case 'LOST_DAMAGED':
    case 'DAMAGED':
    case 'RE_SHIP':
      color = palette.error.light;
      break;

    case 'Synced':
    case 'FastMoving-Healthy':
    case 'Completed':
    case 'completed':
    case 'COMPLETED':
    case 'STOCK_INBOUNDED':
    case 'SUCCESS':
    case 'Success':
    case 'Processed':
    case 'Healthy':
    case 'Active':
    case 'Small':
      color = palette.success.main;
      break;

    case 'ON_TIME':
    case 'ON-TIME':
    case 'On Time':
    case 'On time':
    case 'On_time':
    case 'on_time':
    case 'on-time':
      color = palette.success.dark;
      break;

    case 'NOT_STARTED':
    case 'DELIVERY_LATE':
      color = palette.pieChart.color9;
      break;

    case 'DELIVERED':
    case 'VERIFIED':
      color = palette.info.main;
      break;

    case 'FULFILLED':
    case completed:
      color = '#50D860';
      break;

    case 'PICKUP_LATE':
      color = palette.error.main;
      break;

    default:
      color = palette.grey[500];
      break;
  }

  return color;
};

export const getCurrencyColor = (label, palette) => {
  let color;

  switch (label) {
    case 'SGD':
    case '$':
      color = palette.pieChart.color1;
      break;
    case 'THB':
    case '฿':
      color = palette.warning.main;
      break;

    case 'INR':
    case '₹':
      color = palette.success.main;
      break;

    case 'PHP':
    case '₱':
      color = palette.pieChart.color2;
      break;

    default:
      color = palette.grey[500];
      break;
  }

  return color;
};

export const getStatusColorforCharts = (label, palette) => {
  let color;

  switch (label) {
    case 'New':
    case 'NEW':
    case 'QUEUED':
    case 'RELEASED':
    case 'ASN_PENDING':
    case 'PENDING_APPROVAL':
    case 'FastMoving':
    case 'X-Small':
      color = palette.color1;
      break;

    case 'Ready For Pickup':
    case 'PICKING':
    case 'PROCESSING':
    case 'Not Present':
    case 'FastMoving-LowStock':
    case 'SlowMoving':
    case 'UNASSIGNED':
    case 'OPEN':
      color = palette.color8;
      break;

    case 'RETURN_COMPLETED':
    case 'ON_HOLD':
    case 'PARTIAL':
    case 'PARTIAL_PICKED':
    case 'VALIDATING':
    case 'In Progress':
    case 'RETURN_IN_PROGRESS':
    case 'SlowMoving-WithStock':
    case 'LowStock':
    case 'Inactive':
    case 'Not Processed':
    case 'ARRIVED_AT_RETURN_HUB':
    case 'RETURN_TRIGGERED':
    case 'RETURN_PARCEL_SORT_READY':
    case 'OUT_FOR_RETURN':
      color = palette.color13;
      break;

    case 'RETURNED':
      color = '#FF6B00';
      break;

    case 'Packed':
    case 'PACKED':
    case 'FULFILLED':
    case 'STOCK_RECEIVED':
    case 'READY_FOR_PICKUP':
    case 'READY_FOR_PICKING':
    case 'Standard':
      color = palette.color12;
      break;

    case 'Ready to Ship':
    case 'READY_FOR_DISPATCH':
    case 'QUALITY_CHECK':
    case 'STOCK_COUNT':
    case 'Large':
      color = palette.color10;
      break;

    case 'TRANSIT_HOLD':
      color = palette.color6;
      break;

    case 'Shipped':
    case 'In Transit':
    case 'IN TRANSIT':
    case 'IN_TRANSIT':
    case 'PACKAGE_PICKED':
    case 'ENROUTE_TO_SORT':
    case 'ARRIVED_AT_SORT':
    case 'ENROUTE_TO_DIST':
    case 'ARRIVED_AT_DIST':
    case 'PARCEL_SORT_READY':
    case 'RESCHEDULE_DELIVERY':
      color = palette.color9;
      break;

    case 'OUT_FOR_DELIVERY_1':
    case 'OUT_FOR_DELIVERY_2':
    case 'OUT_FOR_DELIVERY_3':
    case 'OUT_FOR_DELIVERY':
      color = palette.color8;
      break;

    case 'PICKED_UP':
    case 'PICKED':
    case 'ASSIGNED':
    case 'PICKUP_PENDING':
    case 'PENDING_PICKUP':
    case 'ASN_APPROVED':
    case 'APPROVED':
      color = palette.color9;
      break;

    case 'Cancelled':
    case 'CANCELLED':
    case 'ASN_REJECTED':
    case 'FastMoving-OutOfStock':
    case 'OutOfStock':
    case 'FAILED':
    case 'Failed':
    case 'Bulky':
    case 'PICKUP_FAILED_1':
    case 'PICKUP_FAILED_2':
    case 'PICKUP_FAILED_3':
      color = palette.color2;
      break;

    case 'CANCELLATION_IN_PROGRESS':
    case 'ERROR':
    case 'PUTAWAY':
    case 'UNPAID':
    case 'X-Large':
    case 'DELIVERY_FAILURE_1':
    case 'DELIVERY_FAILURE_2':
    case 'DELIVERY_FAILURE_3':
    case 'LOST':
    case 'LOST_DAMAGED':
    case 'DAMAGED':
    case 'RE_SHIP':
      color = palette.color11;
      break;

    case 'Synced':
    case 'FastMoving-Healthy':
    case 'Completed':
    case 'completed':
    case 'COMPLETED':
    case 'STOCK_INBOUNDED':
    case 'SUCCESS':
    case 'Success':
    case 'Processed':
    case 'Healthy':
    case 'Active':
    case 'Small':
      color = palette.color1;
      break;

    case 'DELIVERED':
      color = palette.color4;
      break;

    default:
      color = palette.color7;
      break;
  }

  return color;
};

export const getMarketplaceSLAColors = (index, palette) => {
  let backgroundColor;
  let borderColor;

  switch (index) {
    case 0:
      backgroundColor = palette.info.main;
      borderColor = palette.info.light;
      break;

    case 1:
      backgroundColor = palette.error.main;
      borderColor = palette.error.light;
      break;

    case 2:
      backgroundColor = palette.primary.main;
      borderColor = palette.primary.light;
      break;

    case 3:
      backgroundColor = palette.warning.main;
      borderColor = palette.warning.light;
      break;

    case 4:
      backgroundColor = palette.secondary.main;
      borderColor = palette.secondary.light;
      break;

    default:
      backgroundColor = palette.grey[500];
      borderColor = palette.grey[300];
      break;
  }

  return {
    backgroundColor,
    borderColor,
  };
};

export const getRequestHeaders = () => ({
  Authorization: `Token ${localStorage.getItem('token')}`,
});

export const populateFreshdeskWidget = ({ subject, fields }) => {
  window.FreshworksWidget('open', 'ticketForm');
  window.FreshworksWidget('prefill', 'ticketForm', {
    subject,
    ...fields,
  });
};

export const isEmptyObj = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const getMarketPlaceLogoPathbyName = (marketplaceName) => {
  if (!marketplaceName) return null;

  // Convert the input to lowercase for case-insensitive matching
  const lowerCaseName = marketplaceName.toLowerCase();

  // Iterate through the marketplace keys
  for (const [key, logoPath] of marketplaceLogos) {
    // Check if the key exists as a word or substring in the input
    if (lowerCaseName.includes(key)) {
      return logoPath;
    }
  }

  return null; // Return null if no match is found
};

// export const getMarketPlaceLogoPathbyName = (marketplaceName) => {
//   let marketplace = marketplaceName?.split(' ')?.length > 1 ? marketplaceName.split(' ')[0] : marketplaceName;

//   switch (marketplace) {
//     case 'capillary':
//       return '/static/img/logos/capillary.svg';
//     case 'bbo':
//       return '/static/img/logos/bbo.svg';
//     case 'zalora':
//       return '/static/img/logos/zalora.svg';
//     case 'custom':
//       return '/static/img/logos/custom.svg';
//     case 'kumu':
//       return '/static/img/logos/kumu.svg';
//     case 'lazada':
//       return '/static/img/logos/lazada.svg';
//     case 'shopee':
//       return '/static/img/logos/shopee.svg';
//     case 'shopify':
//       return '/static/img/logos/shopify.svg';
//     case 'tiktok':
//       return '/static/img/logos/tiktok.svg';
//     case 'woocommerce':
//       return '/static/img/logos/woocommerce.svg';
//     case 'amazon':
//       return '/static/img/logos/amazon.svg';
//     case 'ebay':
//       return '/static/img/logos/ebay.svg';
//     case 'tokopedia':
//       return '/static/img/logos/tokopedia.svg';
//     default:
//       break;
//   }
// };

export const isNil = (value) => value === null || value === undefined;

const defaultError = notifications.ERROR.something_wrong;
export const errorParser = (error) => {
  // eslint-disable-next-line no-unused-vars
  let er;
  if (error.response?.data?.error) {
    er = error.response.data.error;
  } else if (error.response?.data?.message) {
    er = error.response?.data?.message;
  } else if (error.response) {
    er = error.response;
  } else if (error.request) {
    er = error.request;
  } else if (error.message) {
    er = error.message;
  } else if (error.data?.error) {
    er = error.data?.error;
  } else {
    // eslint-disable-next-line no-unused-vars
    er = defaultError;
  }

  return er;
};

export const expiry_logo = '/static/img/icons/expiry.png';
export const fragile_logo = '/static/img/icons/fragile.png';

export const isOperableArray = (obj) => obj && Array.isArray(obj) && obj.length > 0;
export const isEmptyArray = (obj) => obj && Array.isArray(obj) && obj.length === 0;

export const filterNullFromTableFilterArray = (itemArr) => itemArr?.filter((item) => item !== null);

export const messageContainer = (str, color, size = '.7rem') => (
  <Typography style={{ fontSize: size }} color={color}>
    {str}
  </Typography>
);
export const datatableWithFallbackRender = (data, fallbackRender) => data || fallbackRender || '-';

//legendContainerNodeStyles = css object
//customLegendNode = function that returns child node to be appended to legend container node
export const createCustomChartLegends = (legendContainerNodeStyles, customLegendNode, customLegendClick) => {
  const getOrCreateLegendList = (_chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'row';
      listContainer.style.margin = 0;
      listContainer.style.padding = 0;

      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, _args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // apply legend container style
      Object.entries(legendContainerNodeStyles).length &&
        Object.entries(legendContainerNodeStyles).forEach((entry) => {
          const key = entry[0];
          const value = entry[1];

          ul.style[key] = value;
        });

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item) => {
        const onClick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        ul.appendChild(customLegendNode(chart, item, customLegendClick ? customLegendClick : onClick));
      });
    },
  };

  return htmlLegendPlugin;
};
